.tabbed-area {
  $font-size__dropdown: 14px;
  $padding-vertical: px-to-em(10px, $font-size__dropdown);
  $padding-horizontal: px-to-em(15px, $font-size__dropdown);

  &__nav {
    position: relative;
    z-index: 5;
    margin-bottom: px-to-em($spacing__md);
  }

  &__nav-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    padding: 0 px-to-em($wrapper-padding--mobile);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-out,
                visibility 0.1s ease-out 0.25s;
  }

  &__nav-link {
    display: block;
    background-color: $color__white;
    width: 100%;
    text-align: left;

    border: 2px solid $color__gray-black-squeeze;
    border-top: 0;
    font-size: px-to-em($font-size__dropdown);
    padding: $padding-vertical $padding-horizontal;

    &:before {
      background-color: transparent;
    }

    &.is-active {
      display: none;
    }
  }

  &__nav-toggle {
    @include font($font-family__body,$font-size__dropdown);

    width: 100%;
    position: relative;
    padding: $padding-vertical $padding-horizontal;
    line-height: 1.1;
    border: 2px solid $color__gray-black-squeeze;
    padding-right: $padding-horizontal * 1.75;
    background-color: $color__white;
    text-align: left;
    text-transform: uppercase;

    &:after {
      $border-size: px-to-em(5px, $font-size__dropdown);

      @include center-align(vertical);

      content: '';
      right: $padding-horizontal;
      width: 0;
      height: 0;
      border-left: $border-size*1.25 solid transparent;
      border-right: $border-size*1.25 solid transparent;
      border-top: $border-size * 2 solid $color__brand-gold;
    }

    &--expanded {
      & + .tabbed-area__nav-list {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s ease-out;
      }
    }
  }

  &__body {
    position: relative;
    overflow: hidden;
    clear: both;

    &--awards {
      .copy-block--theme-grey,
      .copy-block--no-margin {
        @include section;
      }
    }
  }


  &__navigator {
   background-color: $color__black;

    & + :not(.contact-callout){
       margin-top: px-to-em($spacing__lg);
   }

    & > .wrapper {
      display: flex;
      flex-wrap: nowrap;
    }

    &-toggle {
      @include font($font-family__body);

      color: $color__brand-gold;
      padding: 1em 0.5em;
      width: 50%;
      text-align: center;
      text-transform: uppercase;
      transition: transform 0.25s ease-out;

      &--previous {

        @include ico-icon-after('arrow-left') {
          display: block;
          color: $color__white;
          transition: transform 0.25s ease-out;
          padding-top: px-to-em($spacing__unit);
        }
      }

      &--next {
        border-left: 1px solid $color__white;

        @include ico-icon-after('arrow-right') {
          display: block;
          padding-left: 0.5em;
          padding-top: px-to-em($spacing__unit);
          text-align: center;
          color: $color__white;
          transition: transform 0.25s ease-out;

        }
      }
      &:first-child:last-child {
        text-align: center;
        width: 100%;
        border-left: 0 none;
      }
    }
  }

  @include respond-to ('small') {
    &__nav-list {
      display: flex;
      flex-wrap: wrap;
      position: static;
      opacity: 1;
      visibility: visible;
      padding: 0;
      top: auto;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }

    &__nav-toggle {
      display: none;
    }

    &__nav-link {
      margin-right: px-to-em($spacing__sm);
      margin-bottom: px-to-em($spacing__sm);
      width: auto;
      background-color: transparent;
      border: 0 none;
      font-size: px-to-em($button__font-size);
      padding: px-to-em($button__padding * 1.5, $button__font-size) px-to-em($button__padding, $button__font-size) px-to-em($button__padding * 1.15, $button__font-size);

      &:before {
        background-color: $color__brand-gold;
      }

      &.is-active {
        display: inline-block;

        &:before {
          height: 100%;
        }
      }
    }

    &__navigator {
      background-color: $color__black;

      &-toggle {
        $icon-size: 20px;
        $arrow-transition-offset: 30%;
        font-size:  px-to-em($font-size__h4);
        text-align: left;
        padding: 1em 1.5em;

        &:hover {
          background-color: $color__brand-gold;
          color: $color__white;
        }

        &--previous {
          text-align: right;

          &:after {
            content: no-open-quote;
            padding-top: 0;
          }

          @include ico-icon-before('arrow-left') {
            font-size: px-to-em($icon-size, $font-size__h4);
            display: inline-block;
            padding-right: 0.5em;
            color: $color__white;
            transition: transform 0.25s ease-out;
            padding-top: 0;
          }

          &:hover {
            &:before {
              transform: translateX($arrow-transition-offset * -1);
            }
          }
        }

        &--next {
          @include ico-icon-after('arrow-right') {
            font-size: px-to-em($icon-size, $font-size__h4);
            padding-top: 0;
          }

          &:hover {
            &:after {
              transform: translateX($arrow-transition-offset);
            }
          }
        }
      }
    }
  }
}


