.copy-block-landing {
  @include section;

  overflow: hidden;
  margin-bottom: px-to-em($spacing__normalize);
  transition: height 0.25s ease-out,
              margin-bottom 0.25s ease-out;

  &.is-collapsed {
    margin-bottom: 0;
  }

  &__content {
    & + & {
      margin-top: px-to-em($spacing__md);
    }
  }

  @include respond-to('small') {
    &__content {
      & + & {
        margin-top: px-to-em($spacing__lg);
      }
    }
  }
}
