.related-insights {
  @include section;

	&__see-all {
    margin-top: px-to-em($spacing__sm, $button__font-size--mobile );

	}

	@include respond-to('small') {
    margin-bottom: px-to-em($spacing__lg);

		&__see-all {
      margin-top: px-to-em($spacing__md,$button__font-size );
      margin-bottom: px-to-em($spacing__md, $button__font-size );
    }

    //modifier if it's inside the context of a tabbed structure it should expand the full width
    .tabbed-area-tile &,
    .share-this__sections &,
    .general-content & {
      max-width: px-to-em($wrapper-max-width--content) +  px-to-em($wrapper-padding * 2);
    }
	}
}
