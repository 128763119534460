.listing-insights-grid {
  $tile-padding: px-to-em($wrapper-padding--mobile);
  $listing-border: px-to-em(1px) solid $color__gray-shady-lady;


  margin: 0 ($tile-padding * -1);

  &__category {
  	@include font($font-family__hind, $font-size__h6 );

  	text-transform: uppercase;
  	display: block;
    margin-bottom: $spacing__unit;
  }

  &__title {
    @include font($size: $font-size__insight--mobile);

    line-height: 1.4;
  }

  &__item {
    & + & {
      border-top: $listing-border;
    }
  }

  &__link {
    display: block;
    color: $color__blue;
    padding: $tile-padding $tile-padding $tile-padding;


    &:hover {
      background-color: $color__blue;
      color: $color__white;
    }
  }


  @include respond-to('small') {
    $listing-padding: px-to-em(44px);
    $tile-padding: px-to-em($wrapper-padding);


    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 ($tile-padding * -1);
    position: relative;

    &__item {
      $grid-width: 50%;
      flex-basis: $grid-width;
      // margin-bottom: $tile-padding;
      position: relative;

      //right border
      &:after {
        content: '';
        display: block;
        position: absolute;
        border-right: $listing-border;
        top: $tile-padding;
        bottom: 100%;
        right: 0;
        z-index: -1;

        transition: bottom 0.5s ease-out;

        .is-animating &{
          bottom: $tile-padding;
        }
      }

      & + & {
        border-top: 0 none;
      }

      &:nth-child(2n+1) {
        //top border
        &:before {
          content: '';
          display: block;
          position: absolute;
          border-top: $listing-border;
          top: 0;
          left: $tile-padding;
          width: 0;
          z-index: -1;
          transition: width 0.75s ease-out;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }

      .is-animating &:nth-child(2n+1) {
        &:before {
          width: calc(200% - #{$tile-padding*2});
        }
      }

      //right border;
      &:nth-child(2n) {
        margin-left: -1px; //offset tile to hide underline once hovered over a tile
        &:after {
          border-right: 0 none;
        }
      }
    }
    &__link {
      display: block;
      height: 100%;
      // margin-left: -1px; //Need boxes to overlap to
      padding: $tile-padding*1.5 $tile-padding $tile-padding * 2;
      box-shadow: 0 0 0 rgba(0,0,0,0);
      position: relative;
      transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s,
                  box-shadow 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

      &:hover {
        box-shadow: $color__box-shadow;
        background-color: transparent;

        &:before {
          top: 0;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        bottom: 0;
        z-index: 1;
        background-color: $color__blue;
        transition: top 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
    &__title,
    &__category {
      position: relative;
      z-index: 2;
    }
	  &__title {
	  	@include font($size: $font-size__insight);

	  }
  }

  @include respond-to('medium') {
    &__item {
      flex-basis: 33.3333%;

      margin-left: -1px;//offset tile to hide underline once hovered over a tile

      //readding border
      &:nth-child(2n) {

        &:after {
          border-right: $listing-border;
        }
      }
      &:nth-child(3n) {
        &:after {
          border-right: 0 none;
        }
      }
      &:nth-child(2n+1) {
        &:before {
          display: none;
        }
      }
      &:nth-child(3n+1) {
        margin-left: 0; //no offset needed for the first tile in row since line is not added.
        //top border
        &:before {
          content: '';
          display: block;
          position: absolute;
          border-top: $listing-border;
          top: 0;
          left: $tile-padding;
          z-index: -1;
          width: 0;
          transition: width 0.9s ease-out;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }

      .is-animating &:nth-child(3n+1) {
        &:before {
          width: calc(300% - #{$tile-padding}*2);
        }
      }
    }
  }
}
