.back-to-top {
  @include center-align(horizontal);

  width: 100%;
  z-index: $zindex__back-to-top;
  top: px-to-em($breadcrumbs-height + $site-header-height + $spacing__md);
  margin-bottom: attr(sections-offset px);
  display: none;

  &__sections {
    position: relative;
  }

  &__trigger {
    float: right;
    right: 0;

    //arrow up icon
    &:before {
      @include center-align(horizontal);
      bottom: 110%;
      font-size: 1.25em;
      color: $color__blue;
      margin-bottom: 0;
      transition: margin-bottom 0.3s;
    }

    &:hover {

      &:before {
        margin-bottom: 0.5em;
      }

      .back-to-top__label {
        &:before {
          height: 100%;
        }
      }
    }
  }

  &__label {
    $border-size: 3px;

    @include font($font-family__body, $font-size__h6 );

    text-transform: uppercase;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $border-size;
      background-color: $color__brand-gold;

      transition: height 0.3s;
      z-index: -1;
    }
  }

	@include respond-to('small') {
    display: block;
    &__label {
      font-size: 1em;
    }

    &__trigger {
      //icon arrrow up
      &:before {
        font-size: px-to-em(26px);
      }
    }
	}
}
