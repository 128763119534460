.rte {
  @include font($font-family__body);

  line-height: 1.5;

  & > * {
    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &--summary {
    @include font($font-family__titles, $font-size__copy-summary--mobile,  'medium');

    line-height: 1.4;
  }

  .intro {
    @include font($font-family__titles, $font-size__copy-summary--mobile,  'medium');

    line-height: 1.4;
  }

  a {
    border-bottom: 2px solid $color__brand-gold;
  }

  h1,h2,h3,h4,h5 {
    font-family: $font-family__titles;

  }

  h2 {
    font-size: px-to-em($font-size__h2--mobile);
    margin-bottom: px-to-em($spacing__sm, $font-size__h2--mobile);
    line-height: 1.3;
  }

  h3 {
    @include font($font-family__body, $font-size__h4--mobile);

    color: $color__black;
    margin-bottom: px-to-em($spacing__sm, $font-size__h4--mobile);
  }

  ul, ol {
    margin-bottom: px-to-em($spacing__normalize);
    padding-left: 1.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
      margin-top: px-to-em(calc($spacing__normalize/2));
    }

  }

  ol {
    list-style-type: decimal;

    ol {
      margin-top: px-to-em(calc($spacing__normalize/2));
    }
  }

  li {
    margin-bottom: px-to-em(calc($spacing__normalize/2));
  }

  blockquote, .blockquote {
    $padding-vertical: $spacing__sm;

    position: relative;
    padding: px-to-em($padding-vertical) 0 px-to-em($padding-vertical * 1.25)  px-to-em($padding-vertical * 1.25);
    margin:  px-to-em($spacing__normalize) 0;

    @include font($font-family__titles, $weight: medium);

    &:after {
      content: '';
      display: block;
      width: 75vw;
      background-color: $color__gray;
      position: absolute;
      top: 0;
      left: 75%;
      height: 99%;
      transform: translateX(-100%);
      z-index: -2;
    }

    cite, .cite {
      margin-bottom: px-to-em($padding-vertical * -1);
    }

    p {
      @include font($size: $font-size__copy-blockquote-mobile);
      line-height: 1.15;
      margin-bottom: 0;
    }

    &:before {
      $font__quote: 75px;
      @include font($size: $font__quote);
      content: "“";
      color: $color__blue;
      position: absolute;
      top: 0;
      left: -2%;
      line-height: 1.2;
      z-index: -1;
    }
  }

  cite, .cite {
    display: block;
    text-align: right;
    color: $color__blue;
    font-style: normal;

    @include font($font-family__body);

    &:before {
      content: "— ";
    }
  }

  img {
    max-width: 100%;
    margin-top: px-to-em($spacing__md);
  }

  @include respond-to('small') {
    font-size: px-to-em($font-size__copy);
    line-height: 1.5;

    a {
      background-image: linear-gradient(to right, #{$color__brand-gold} 100%, #{$color__brand-gold} 100%);
      background-position: 0 px-to-em(22px);
      background-repeat: no-repeat;
      background-size: 100% 2px;
      transition: background 0.25s;
      border-bottom: 0 none;

      &:hover {
        background-size: 0 2px;
      }
    }

    &--summary {
      font-size: px-to-em($font-size__copy-summary);
      line-height: 1.6;
            a { background-position: 0 1.00em;} 
    }

    .intro {
      font-size: px-to-em($font-size__copy-summary, $font-size__copy);
      line-height: 1.6;
    }


    h2 {
      font-size: px-to-em($font-size__h2, $font-size__copy);
      margin-bottom: px-to-em($spacing__sm, $font-size__h2);
      line-height: 1.3;
    }

    h3 {
      font-size: px-to-em($font-size__h4, $font-size__copy);

      margin-bottom: px-to-em($spacing__sm, $font-size__h4);
    }

    blockquote, .blockquote {
      $padding-vertical: 35px;

      padding-top: px-to-em($padding-vertical);
      padding-bottom: px-to-em($padding-vertical * 1.25);
      margin: px-to-em($spacing__lg, $font-size__copy) 0;

      &:before {
        $font__quote: 120px;
        font-size: px-to-em($font__quote);
      }

      cite, .cite {
        margin-bottom: px-to-em($padding-vertical * -1);
      }

      p {
        font-size:  px-to-em($font-size__copy-blockquote, $font-size__copy);
      }
    }
  }

  //education bar admission styles

  .rte-bar-admission-list {
    list-style: none;
    padding-left: 0;
  }

  .rte-bar-year {
    &:before {
      content: ', '
    }
  }

  .rte-bar-school {
    font-weight: map-get($font-weights, 'semibold');
  }

  .rte-education-school {
    @include font($family: $font-family__body, $weight: 'semibold');
  }

  .rte-education {
    & + .rte-education {
      margin-top: px-to-em($spacing__unit);
    }
  }

  .rte-education-distinction {
    font-size: px-to-em($font-size__h6);
    line-height: 1.2;
    li {
      margin-bottom: px-to-em($spacing__unit);
    }
  }

  @include respond-to('medium') {
    blockquote,
    .blockquote {
      &:before {
        left: auto;
        right: 100%;
      }
    }
  }
}

.rte-multi-column {
  margin-bottom: px-to-em($spacing__sm);


  &--background {
    position: relative;
    padding-top: px-to-em($spacing__normalize);
    padding-bottom: px-to-em(calc($spacing__normalize/2)) ;
    color: $color__blue;

    &:before {
      content: '';

      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      width: 100vw;
      height: 100%;
      transform: translateX(-50%);
      background-color: $color__gray;
      z-index: -1;
    }
  }
}

.rte-multi-column-block {
  margin-bottom: px-to-em($spacing__normalize);
}

@include respond-to('small') {
  .rte-multi-column {
    margin-bottom: px-to-em($spacing__md);
    @include clearfix;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;


    ul,
    ol {
      &:first-child:last-child {
        width: 100%;
      }
    }

    &--background {
      padding-top: px-to-em($spacing__md) ;
      padding-bottom: px-to-em(calc($spacing__normalize/2)) ;
    }
  }

  .rte-multi-column-block {
    width: 48%;
    margin-bottom: px-to-em(calc($spacing__normalize/2));
  }

  .rte-two-column-list {
    $credentials-grid-spacing: 4%;

    columns: 2 auto;
    column-gap: $credentials-grid-spacing;


    .rte &#{&}  {
      margin-bottom: px-to-em($spacing__normalize);
      padding-left: 0;
    }

    > li {
      position: relative;
      break-inside: avoid;
      overflow: hidden;
      padding-left: 1.1em;

      &:before {
        content: '•';

        position: absolute;
        display: inline-block;
        left: 0;
      }
    }
  }
}
