.forms {
  $font-size__input: 18px;
  $input-padding-sides: px-to-em($spacing__unit * 1.25, $font-size__input);
  $input-padding-vertical: px-to-em($spacing__unit * 1.25, $font-size__input);

  @include section;

  &__field {
    margin-bottom: $spacing__normalize;
      &--ns {
          display: none;
      }
  }

  &__grid {
    margin-bottom: px-to-em($spacing__unit);
  }

  input {
    width: 100%;
    font-size: px-to-em($font-size__input);
    padding:$input-padding-vertical $input-padding-sides ;
    color: $color__black;

    @include placeholder() {
      color: $color__black;
    }

    &:not([type="radio"]) {
      background-color: $color__gray;
      border: 1px solid $color__blue;
    }
  }

  textarea {
    width: 100%;
    background-color: $color__gray;
    border: 1px solid $color__blue;
    font-size: px-to-em($font-size__input);
    padding:$input-padding-vertical $input-padding-sides ;
    color: $color__black;
    height: px-to-em(120px);

    &.is-invalid {
      border-color: $color__red;
    }

    @include placeholder() {
      color: $color__black;
    }
  }

  &__message {
    display: none;

    &.show-message{
        display: block;
    }

    &__error {
        color: $color__black;
    }
  }

  //validation helpers
  .is-invalid {
    &:not([type="radio"]) {
      border-color: $color__red;

      & + .forms__invalid-note {
        display: inline-block;
      }
    }

    & + .forms__invalid-note {
      display: inline-block;
    }

    &.newsletter-invalid{
      display:block;
      position:absolute;
      bottom:0;
    }
  }

  &__invalid-note {
    @include font($font-family__body);

    color: $color__gray-text;
    margin-top: 0.5em;
    display: none;
  }


  @include respond-to('small') {
    $font-size__input: 20px;
    $input-padding-sides: px-to-em($spacing__sm, $font-size__input);
    $input-padding-vertical: px-to-em($spacing__unit * 1.5, $font-size__input);


    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__field {
      width: 50%;
      padding-right: $spacing__normalize;

      &--full {
        width: 100%;
      }
    }

    input {
      &:not([type="radio"]) {
        font-size: px-to-em($font-size__input);
        padding: $input-padding-vertical $input-padding-sides;
      }
    }

    textarea {
      font-size: px-to-em($font-size__input);
      padding: $input-padding-vertical $input-padding-sides ;
    }

  }
  .forms__field--newsletters{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding-right:0;
    padding-bottom: 24px;
    position:relative;
    .column{
      flex: 1;
      .checkbox{
          font-family:$font-family__titles;
          .input{
            width:15px;
            margin-right:5px;
            float:left;
          }
      }
    }
  }
}
