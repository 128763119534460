.featured-item {
  @include section;
  background-color: $color__gray-black-squeeze;

  &--prominent {
    background-color: $color__blue;

    .featured-item__content {
      background-color: $color__blue;
      color: $color__white;
    }

    .featured-item__cta {
      color: $color__white;

      &:hover {
        color: $color__black;
      }
    }

    .featured-item__title {

      &:hover {
        & + .animate-reveal {
          .featured-item__cta {
            color: $color__black;
          }
        }
      }
    }
  }

	.wrapper {
		@include clearfix;
  }

	&__figure {
		margin: px-to-em($wrapper-padding) * -1 auto 0 px-to-em($wrapper-padding) * -1;
    float: left;
		width: 100%;
		padding-bottom: 80%;
		overflow: hidden;
		position: relative;

		> img {
			width: 100%;
			display: block;
			position: absolute;
		}
  }

  &__content {
    clear: left;
    padding: px-to-em($spacing__md) 0;
    background-color: $color__gray-black-squeeze;

  }

  &__title {
    @include font($size: $font-size__h2--mobile, $weight: black);

    line-height: 1.3;
    display: block;

    &:hover {
      & + .animate-reveal {
        .featured-item__cta {
          &:before {
            height: 100%;
          }
        }
      }
    }
  }

  &__support-text {
    @include font($family: $font-family__body, $size: $font-size__h6, $size-base: $font-size__h2--mobile);

    display: block;
    margin-top: 1em;

    ul, ol {
        margin-bottom: px-to-em($spacing__normalize);
        padding-left: 1.1em;
        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        list-style-type: disc;

        ul {
          list-style-type: circle;
          margin-top: px-to-em(calc($spacing__normalize/2));
        }

      }

      ol {
        list-style-type: decimal;

        ol {
          margin-top: px-to-em(calc($spacing__normalize/2));
        }
      }
  }

  &__cta {
  	margin-top: px-to-em($spacing__sm, $button__font-size);
  }

  &__tag {
    @include font($font-family__body, $font-size__h6 );

    margin-bottom: px-to-em($spacing__sm);
    text-transform: uppercase;
  }

  @include respond-to('small') {
    $content-height: px-to-em(400px);

    background-color: transparent;

  	&__figure {
  		float: left;
  		width: 45%;
  		max-width: px-to-em(510px);
      margin: px-to-em($spacing__md)  px-to-em($spacing__md) 0  px-to-em($wrapper-padding * -1) ;
      padding-bottom: 0;
      overflow: hidden;


  		> img {
        position: static;
      }

      &.animate-reveal {
        > img {
          transform: translateY(100%);
          transition: transform 0.5s ease-in;
        }
      }

    }

    &.is-animating {
      .featured-item__figure {
        > img {
          transform: translateY(0);
        }
      }
    }

  	&__title {
    	font-size: px-to-em($font-size__h2);
    }

    &__content {
      clear: none;
      padding: px-to-em($spacing__md);
      min-height: $content-height;
    }
  }

  @include respond-to('medium') {
    $content-height: px-to-em(510px);

    &__figure {
      margin-right: px-to-em($spacing__lg);
      margin-left: 0;
      margin-top: px-to-em($spacing__lg);


      & + .featured-item__content {
        min-height: $content-height;
        margin-right: 0;
      }
    }

    &__content {
      clear: none;
      padding-top: px-to-em($spacing__lg);
      padding-bottom: px-to-em($spacing__lg);
      margin-left: px-to-em($spacing__indentation);
      margin-right: px-to-em($spacing__indentation);
    }
  }
}
