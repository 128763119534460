.services-group {
  @include section;

  $grid-spacing: px-to-em(calc($spacing__sm/2));


  &__collection {
    .offering-card {
      padding-left: 1.2em;
      height: px-to-em(110px);
    }
  }

  &__expand-toggle {
    margin-bottom: px-to-em($spacing__sm);
    &:after {
      content: '+';
    }

    &.is-expanded {
      &:after {
        content: '–';
      }
    }
  }

	@include respond-to('small') {
    &__grid {
      margin: 0 $grid-spacing * -1;
      display:flex;
      flex-wrap: wrap;
      clear: both;
    }

    &__expand-toggle {
      float: right;
      margin-bottom: 0;
      margin-top: px-to-em($spacing__lg * -1.5);
      position: relative;
      z-index: 5;
      font-size: 1em;

    }

    &__collection  {
      padding: 0 $grid-spacing;
      width: 50%;
      // display: inline-block;
      // margin-left: px-to-em(-4px);
      // vertical-align: top;
    }
  }
}
