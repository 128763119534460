$wrapper-padding: 35px;
$wrapper-padding--mobile: 20px;

$wrapper-max-width: 1200px;
$wrapper-max-width--content: 1000px;

.wrapper {
  max-width: px-to-em($wrapper-max-width) +  px-to-em($wrapper-padding * 2);
  padding-left: px-to-em($wrapper-padding--mobile);
  padding-right: px-to-em($wrapper-padding--mobile);
  margin-left: auto;
	margin-right: auto;


  &--content {
  		max-width: px-to-em($wrapper-max-width--content) +  px-to-em($wrapper-padding * 2);
		padding-left: px-to-em($wrapper-padding--mobile);
		padding-right: px-to-em($wrapper-padding--mobile);
		margin-left: auto;
  		margin-right: auto;
	}

	@include respond-to('small') {
		padding-left: px-to-em($wrapper-padding);
  		padding-right: px-to-em($wrapper-padding);

		&--content {
			padding-left: px-to-em($wrapper-padding);
  			padding-right: px-to-em($wrapper-padding);
		}
	}

	/*
	@media only screen and (max-width: $screen-x-tiny) {
		&--content {
			padding-left: px-to-em($wrapper-padding);
  			padding-right: px-to-em($wrapper-padding);
		}
	}
	*/
}
