.offering-card {
  $border__height: 3px;

  display: block;
  margin-bottom: px-to-em($spacing__sm);
  display: flex;
  align-items: center;
  background-color: $color__gray;
  min-height: px-to-em(110px);
  width: 100%;
  padding: px-to-em($spacing__unit);
  position: relative;
  transition: color 0.25s ease-out 0.15s;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $border__height;
    background-color: $color__blue;
    display: block;
    transition: height 0.3s;

  }

  &__title {
    @include font($weight: 'bold');
    @include titles-entity();

    width: 100%;
    text-align: center;
    position: relative;

	/*
	width: 75%;
	position: absolute;
	left: 50%;
	transform: translateY(-50%);
	transform: translateX(-50%);
	*/
  }

  @include respond-to('tiny') {
	  &:hover {
		color: $color__white;

		&:before {
		  height: 100%;
		}
	  }
  }
}
