﻿.site-search-result {
	margin: 1.5em 0;
	border-bottom: 1px solid $color__gray-black-squeeze;
    padding-bottom: 1.5em;

	.site-search-result__title {
		font-size: 1.5em;
		color: $color__blue;
		transition: color .25s;
		&:hover {
			color: $color__black;
		}
	}	
	.site-search-result__url {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;		
		color: $color__blue;
	}
	.site-search-result__summary {
	
	}
}