.subscription-form{
	padding:3em 4em;
	margin-bottom:5em;
	background-color:$color__gray;
	display:flex;
	align-items:center;
	&__left-column{
		width:70%;
	}
	&__heading{
		font-size:35px;
		color: $color__blue;
		margin-bottom: 1rem;
	}
	&__text{
		float:left;
		color: $color__blue;
		p {
			margin-bottom:.75em;
		}
	}
	&__button{
		float:right;
		a{
			margin:none !important;
		}
	}
	@media only screen and (max-width: $screen-sm) {
     		flex-direction:column;
     		&__left-column{
     			width:100%;
     		}
    	}

}
