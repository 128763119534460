.location-metabar {
  @include section;

  background-color: $color__black;
  color: $color__white;
  padding: px-to-em($spacing__sm) 0;
  text-align: center;

  &__item {
    @include font($font-family__body, $font-size__h6);

    display: inline-block;
    min-width: 48%;

    & + & {
      margin-top: 0.5em;
    }
  }

  &__label {
    &:after {
      content: ":";
    }
  }

  &__entry {
    color: $color__brand-gold;
  }

  @include respond-to('landscape') {
    padding: 0.5em 0;
    text-align: left;

    &__item {
      min-width: auto;
      margin-right: 1em;

      & + & {
        margin-top: 0;
      }
    }
  }

  @include respond-to('small') {

    &__author,
    &__location-link {
      &:hover {
        color: $color__white;
      }
    }
  }
}
