.bio-contactbar {
  @include section;

  background-color: $color__black;
  color: $color__white;
  padding-bottom: px-to-em($spacing__sm);
  text-align: center;

  &__meta-item {
    @include font($font-family__body, $font-size__h6);
    display: inline-block;
    min-width: 48%;

    & + & {
      margin-top: 0.5em;
    }
  }

  &__label {
    &:after {
      content: ":";
    }
  }

  &__entry {
    color: $color__brand-gold;
  }

  @include respond-to('landscape') {
    padding: 0.5em 0;
    text-align: left;

    &__meta-item {
      min-width: auto;
      margin-right: 1em;
    }
  }

  @include respond-to('small') {

    &__entry {
      @at-root a#{&} {
        &:hover {
          color: $color__white;
        }
      }
    }
  }

  @include respond-to('medium') {
    &__meta-item {
      margin-right: 3%;
    }
  }
}
.no-bg {
    background-color: transparent;
}