.site-footer {
  background-color: $color__blue;
  color: $color__white;
  padding-bottom: px-to-em($spacing__md);

  $font-size: 14px;


  &__copyright {
		@include font($font-family__hind, $font-size);

		margin-top: px-to-em($spacing__md);
	}

	&__vendors {
		text-align: center;
	}

	&__vendor-item {
		& + & {
			margin-top: 1em;
		}
  }

  &__nav {
    text-align: center;
  }

	&__collection {

    @include font($font-family__hind, $font-size, 'semibold');
	}

	&__collection-title {
    margin-bottom: 1em;
    font-weight: map-get($font-weights, bold);

    @include ico-icon-after('arrow-up') {
      margin-left: 0.5em;
      transform: rotateX(180deg);
      font-size: 0.75rem;
      transition: transform 0.25s linear;
    }

    & + .site-footer__nav-list {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.25s ease-in-out;
    }

    &.show-dropdown {
      &:after {
        transform: rotateX(0);
      }

      & + .site-footer__nav-list {
        max-height: px-to-em(500px);
      }
    }
	}

	&__nav-link {
		display: inline-block;
		margin-bottom: 1em;
		text-transform: uppercase;

		&:hover {
			color: $color__white;
		}
	}

	&__vendor-item {
		margin-right: 1em;
		vertical-align: middle;
	}

	.social-links {
		margin-top: 1em;
	}

	.social-links__item {

		& + .social-links__item {
			margin-left: 10%;
		}
	}

	.social-links__icon {
		color: $color__brand-gold;
		font-size: px-to-em(24px);

		&:hover {
			color: $color__white;
    }
	}

	@include respond-to('landscape') {
    padding-top: px-to-em($spacing__md);

		&__nav {
			flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      text-align: left;
		}

		&__collection {
			flex-basis: 25%;
      padding-right: 1em;

      &:last-child {
        flex-basis: 25%;
        margin-top: 0;
      }
    }

    &__collection-title {
      font-weight: map-get($font-weights, regular);
      margin-bottom: 0.5em;

      &:after {
        content: none;
      }

      & + .site-footer__nav-list {
        overflow: visible;
        max-height: inherit;
      }
    }
	}


  @include respond-to('small') {
  	padding: px-to-em($spacing__lg) 0;

  	&__vendors {
			text-align: left;
		}

		&__body {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
		}

		&__copyright {
			width: 26%;
			margin-left: 3%;
			margin-top: 0;
    }

    &__nav-link {
      @include link-transition($color__brand-gold);
    }
  }

  @include respond-to('medium') {
  	&__copyright {
  		width: 30%;
			max-width: px-to-em(335px, $font-size);
			margin-left: 8%;
		}
  }
}
