.locations-served {
  @include section;

  position: relative;
  padding-left: 0;

  &__figure {
    position: absolute;
		width: 100%;
    height: 100%;
    left: 0;
		top: 0;
    z-index: -1;
    overflow: hidden;
  }

	&__cover {
    @include bg-cover;

    height: 100%;
    width: 100%;
    transition-duration: 0.5s;
  }

  &__body {
    padding-top: 20%;
  }

  &__copy {
    background-color: $color__white;
    padding: px-to-em($wrapper-padding--mobile) px-to-em($wrapper-padding--mobile) px-to-em($spacing__md);
    width: 90%;
  }

  &__heading {
    @include titles-heading;

    display: block;
    margin-bottom: px-to-em($spacing__sm, $font-size__h1--mobile);
  }

  &__region {
    color: $color__blue;
		display: block;
    overflow: hidden;
    position: relative;

    &.is-transition {
      .locations-served__region-label {
        animation: maskAnimation 1.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  }

  &__region-label {
    transform: translateY(0);
    display: inline-block;
  }

  @include respond-to('landscape') {

    &__copy {
      width: 80%;
    }
  }

  @include respond-to('small') {
    padding-left: px-to-em($wrapper-padding);

    &__body {
      position: relative;
    }

    &__copy {
      width: 75%;
      padding: px-to-em($spacing__md);
      max-width: px-to-em(475px);
      min-height: px-to-em(250px);
    }

    &__region-prefix {
      vertical-align: middle;

      &:before {
        content: '';
        display: block;
      }
    }

    &__region {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
