.hamburger {
  $bun-width: px-to-em(35px);
  $bun-height: 3px;
  $bun-radius: 3px;
  $bun-spacing: 8px;


  $animation: 0.2s;

  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: $bun-width;
  padding: 0;
  height: px-to-em($bun-height * 3) + px-to-em($bun-spacing * 2);
  overflow: visible;
  backface-visibility: hidden;
  transition: transform $animation ease-out;


  &__buns {
    display: inline-block;
    height:$bun-height;
    border-radius: $bun-radius;
    background-color: $color__brand-gold;
    position: absolute;
    left: 0;
    right:0;
    top: 0;
    backface-visibility: hidden;
    transition: background-color $animation ease-out;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: px-to-em($bun-height);
      border-radius:  px-to-em($bun-radius);
      background-color: $color__brand-gold;
      transition: top $animation ease-in-out $animation,
                  transform $animation ease-in-out;
    }

    &:before {
      top: px-to-em($bun-height) + px-to-em($bun-spacing);
    }

    &:after {
      top: px-to-em($bun-height * 2) + px-to-em($bun-spacing * 2);
    }
  }

  .site-header.show-menu &{
    transform: translateY(px-to-em($bun-height * 2));
    transition: transform $animation ease-out $animation;

    .hamburger__buns {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:before,
      &:after {
        transition: top $animation ease-in-out,
          transform $animation ease-in-out $animation;
      }

      &:before {
        transform: rotate(-45deg);
        top: 0;
      }

      &:after {
        transform: rotate(45deg);
        top: 0;
      }
    }
  }

  @include respond-to('small') {
    display: none;
  }
}
