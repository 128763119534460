.page-title {
	@include section;

  background-color: $color__blue;
  position: relative;
  padding: px-to-em($spacing__lg) 0 0 0;
  text-align: center;
  color: $color__white;

  &--professional-landing {
    margin-bottom: 0.5em;

    @include respond-to('small') {
      padding: 5em 0 !important;
    }
  }

  //Modifier for Article Detail page
  &--article {
    margin-bottom: 0;

    .page-title__label {
      font-size: px-to-em($font-size__h1--mobile);
      line-height: 1.3;
    }
  }

  &--location {
    margin-bottom: 0;        
    
    
    .page-title__details {
      background-color: $color__black;
      padding: px-to-em($wrapper-padding--mobile);
      margin-left: px-to-em($wrapper-padding--mobile * -1);
      margin-right: px-to-em($wrapper-padding--mobile * -1);
      margin-top: px-to-em($spacing__lg);
    }
  }

  > .wrapper {
    position: relative;
    z-index: 3;
  }

  &__label {
    text-align: left;
    line-height: 1.1;
    font-size: px-to-em($font-size__page-title--mobile);
  }

  &__img {
    @include bg-cover;

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background-position: center;
  }

  &__cta {
    $border-size: 3px;
    margin-top: 1em;

    > a {
      @include font($font-family__body, $button__font-size--mobile);

      display: inline-block;
      position: relative;
      padding: px-to-em(calc($button__padding / 2), $button__font-size--mobile) px-to-em($button__padding, $button__font-size--mobile);
      text-transform: uppercase;
      transition: color 0.3s,
                  background-color 0.3s;

      &:hover {
        color: $color__black;

        &:before {
          height: 100%;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $border-size;
        background-color: $color__brand-gold;

        transition: height 0.3s;
        z-index: -1;
      }
    }
  }

  &__details {
    position: relative;
    z-index: 4;
  }

  &__summary {
    @include titles-subheading();

    text-align: left;

    .address {
      display: block;
      white-space: pre-line;
    }
  }

  &__service{
    margin-bottom:0;
  }

  @include respond-to('landscape') {
    &--location {
      padding-bottom: px-to-em($spacing__lg);
      height: auto;      
      
      .page-title__details {
        background-color: transparent;
        padding: 0;
        margin:0;
      }
    }

    &__summary {
      margin-top: 1em;
    }
  }

	@include respond-to('small') {
    $title-minheight: 160px;

    text-align: right;
    padding: px-to-em($spacing__lg*2) 0 px-to-em($spacing__xl);

    &--article {
      margin-bottom: 0;

      .page-title__label {
        font-size: px-to-em($font-size__h1);
      }
    }

    &--location {
      padding-bottom: px-to-em($spacing__xl);
      height: auto;
    }


    &__cta {
      margin-top: 2em;

      > a {
        font-size: px-to-em($button__font-size);
        padding: px-to-em($button__padding * 1.5, $button__font-size) px-to-em($button__padding, $button__font-size)  px-to-em($button__padding * 1.15, $button__font-size);
      }

      .attorney-cta {
          float:left;
//          text-align: left;
      }
    }

    &__label {
      font-size: px-to-em($font-size__page-title);
    }
	}
}
