.social-links {

  &__item {
    display: inline;
  }

  &__icon {
    @include ico-icon();
  }

  @include respond-to('small') {
    &__icon {
      transition: color 0.25s;
    }
  }
}
