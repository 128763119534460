.advanced-search {
  margin-bottom: px-to-em($spacing__md);
  text-align: center;

  &__filters {
    text-align: left;
    display: none;

    &--expanded {
      display: block;
    }
  }

  &__toggle {
    margin-bottom: px-to-em($spacing__sm);
  }

  @include respond-to('small') {
    margin-bottom: px-to-em($spacing__md);
    text-align: right;


    &__toggle {
      margin-bottom: px-to-em($spacing__md);
    }

    &__filters {
      display: none;
      flex-wrap: wrap;
      justify-content: space-between;

      &--expanded {
        display: flex;
      }
    }

    .select-dropdown {
      width: 49%;
    }
  }
}
