$site-header-height: 115px;
$site-header-height--mobile: 70px;

.site-header {
	$site-header__icon-size: 20px;
	height: px-to-em($site-header-height--mobile);
  position: fixed;
  width: 100%;
  z-index: $zindex__site-header;
  background-color: rgba(255, 255, 255, 0.9);

  will-change: transform;
  transition: transform 200ms linear;

  &__frame {
    height: px-to-em($site-header-height--mobile);
  }

  &__experience-editor{
      position: relative;
      height: auto;
      min-height: 2em;
  }

  &__logo {
  	display: block;
  	width: px-to-em(150px);
  	position: relative;
  	z-index: 2;
  }

  &__body {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 100%;
	}

	&__search {
		position: absolute;
		height: 100vh;
		top: 0;
		padding: px-to-em($site-header-height--mobile + $spacing__md) px-to-em($wrapper-padding--mobile) 0;
		left: 0;
		width: 100%;
    z-index: 1;
    background-color: $color__white--opaque;
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition-timing__site-nav ease-in,
                visibility $transition-timing__site-nav ease-in;
	}

	&__search-trigger {
		position: relative;
		background-color: transparent;
		border-color: transparent;
		font-size: px-to-em($site-header__icon-size);
		z-index: 2;
    vertical-align: middle;
    width: px-to-em(35px, $site-header__icon-size );

    &:hover {
      color: $color__brand-gold;
    }
	}

  &.show-search {
    .site-header__search-trigger {
      $font-size: 26px;

      font-size: px-to-em($font-size);
      width: px-to-em(35px,$font-size);

      &:before {
        content: map-get($icons, close);
      }
    }

    .site-header__search {
      opacity: 1;
      visibility: visible;
      transition: opacity $transition-timing__site-nav ease-in;
    }
  }

  &.show-menu {
    .site-nav {
      opacity: 1;
      visibility: visible;
      transition: opacity $transition-timing__site-nav ease-in;
    }

    .site-nav__link {
      transform: translateY(0);
      transition-delay: $transition-timing__reveal;
    }
  }

  @include respond-to('small') {
    $searchbox-width: px-to-em(275px) ;

    height: px-to-em($site-header-height);

    &__frame {
      height: px-to-em($site-header-height);
    }

    &__experience-editor{
      height: auto;
      min-height: 2em;
    }

  	&__logo {
  		width: px-to-em(225px);
  	}

  	&__options {
  		position: relative;
  	}
  	&__search {

      background-color: transparent;
  		display: inline-block;
  		position: relative;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
  		width: $searchbox-width;
  		padding:0 0 0 2em;
  		margin-left: 1.5em;
  		margin-right: -0.5em;
  		transition: width 0.5s,
  								padding 0.5s;
  		vertical-align: middle;
  		width: 0;

  		//mobile overrides
  		height: auto;
  		top: auto;
  		left: auto;
  		transform: none;

  		.searchbox {
  			overflow: hidden;
  		}

  		.searchbox__input {
  			padding-left: 1em;
  		}

  		.searchbox__button {
  			opacity: 0;
  			visibility: hidden;
  		}
  	}

  	&__search-trigger {
      @include center-align(vertical);

      top: 45%;
			right: 0;
      text-align: right;
      width: auto;
  	}

    &.show-search {
      .site-header__search-trigger {
        font-size: px-to-em($site-header__icon-size);
        width: auto;

        &:before {
          content: map-get($icons, search);
        }
      }

      .site-header__search {
        transition: width 0.5s,
                    padding 0.5s;
        padding-left: 0;
        width: $searchbox-width;
      }
    }
  }

  @include respond-to('medium') {
  	&__logo {
  		width: auto;
  	}
  }
}
