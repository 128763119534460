.search-criteria {
  @include font($font-family__body);
  display: none;

  &--expanded {
    display: block;
  }

  .wrapper {


    &:after {
      content: '';

      display: block;
      padding-top: px-to-em($spacing__sm);
      border-bottom: 1px solid $color__gray-black-squeeze;
    }
  }

  &__term-label,
  &__filters-label,
  &__clear  {
    text-transform: uppercase;
    font-weight: map-get($font-weights, semibold);

    &:after {
      content: ":";
    }
  }

  &__filters,
  &__clear {
    margin-top: px-to-em($spacing__sm);
  }

  &__clear {
    padding: 0;
    color: $color__brand-gold;

    &:after {
      content:'';
    }
  }

  &__filters-applied {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75em;
  }

  &__remove-filter {
    $icon-x-dimension: 18px;
    $icon-x-padding: 6px;

    position: relative;
    width: px-to-em($icon-x-dimension + $icon-x-padding);
    height: px-to-em($icon-x-dimension);
    padding: 0;
    border: 0 none;
    border-left: px-to-em($icon-x-padding) solid transparent;
    vertical-align: middle;


    &:before,
    &:after {
      content: '';

      position: absolute;
      width: 100%;
      height: px-to-em(2px);
      background-color: $color__brand-gold;
      left: 0;
      bottom: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  @include respond-to('small') {
    margin-bottom: px-to-em($spacing__md);


    .wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      position: relative;
      padding-bottom: px-to-em($spacing__sm);

      &:after {
        content: '';

        position: absolute;
        bottom: 0;
        left: px-to-em($wrapper-padding);
        right: px-to-em($wrapper-padding);
      }

    }

    &__filters {
      flex-grow: 1;
      margin-top: 0;
    }

    &__term {
      padding-right: 1em;
      flex-shrink: 0;
      max-width: 50%;
    }

    &__clear {
      flex-shrink: 0;
      margin-top: 0;
      margin-left: 1em;
    }

    &__remove-filter {
      vertical-align: middle;
    }
  }
}
