.read-more {

  &__expandable {
    overflow: hidden;
    margin-bottom: px-to-em($spacing__normalize);
    height: 0;
    transition: height 0.25s ease-out;

    &:before {
      content: '';
      display: block;
      height: px-to-em($spacing__normalize);
    }
  }

  @include respond-to('small') {

  }
}