.insights-search {
  .searchbox-hero {
    max-width: px-to-em($wrapper-max-width--content) +  px-to-em($wrapper-padding * 2);
    margin-bottom: px-to-em($spacing__md);
  }

  &__promo {
    transition: opacity 1s ease;
  }

  @include respond-to('small') {
    &__promo {
      //modifier if it's inside the context of a tabbed structure it should expand the full width
      .related-insights {
        max-width: px-to-em($wrapper-max-width--content) +  px-to-em($wrapper-padding * 2);
      }
    }
  }
}
