.select-dropdown {
  @include clearfix;

  $font-size__dropdown: 18px;
  $padding-vertical: px-to-em(12px, $font-size__dropdown);
  $padding-horizontal: px-to-em(13px, $font-size__dropdown);

  margin-bottom: px-to-em($spacing__sm);
  position: relative;

  &__select {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background: transparent;
    border: 0;
    font-family: $font-family__body;
  }

  &__toggle {
    @include font($font-family__body,  $font-size__dropdown);

    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    position: relative;
    border: 2px solid $color__gray-black-squeeze;
    padding: $padding-vertical $padding-horizontal;
    padding-right: $padding-horizontal * 1.75;
    position: relative;
    text-transform: uppercase;

    &:after {
      $border-size: px-to-em(5px, $font-size__dropdown);

      @include center-align(vertical);

      content: '';
      right: $padding-horizontal;
      width: 0;
      height: 0;
      border-left: $border-size*1.25 solid transparent;
      border-right: $border-size*1.25 solid transparent;
      border-top: $border-size * 2 solid $color__brand-gold;
    }
  }

  @include respond-to('small') {
    $font-size__dropdown: 20px;

    &__toggle {
      font-size: px-to-em($font-size__dropdown);
    }
  }
}
