$breadcrumbs-height: 40px;
$breadcrumbs-height--mobile: 32px;

.breadcrumbs {
  $font-size: 13px;

  display: none; //will not show on mobile
  position: fixed;
  z-index: $zindex__site-header;
  height: px-to-em($breadcrumbs-height--mobile);
  width: 100%;
  padding: 0.25em 0;
  background-color: $color__black;
  will-change: transform;
  transition: transform 200ms linear;

  &__experience-editor{
      position: relative;
  }
  &__links {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }

  &__links-item {
    @include font($font-family__body, $font-size);

    line-height: 1.1;
    display: inline;
  }

  &__title {
    color: $color__white;
  }

  a.breadcrumbs__title {
    color: $color__brand-gold;

    display: inline-block;
    padding-right: 0.5em;
    margin-right: 0.25em;
    border-right: 1px solid $color__gray-black-squeeze;
  }

  @include respond-to('small') {
    display: block;
    padding: 0.5em 0;
    height: px-to-em($breadcrumbs-height);

    &.headroom--pinned {
      transform: translateY(0%);

      & ~ .site-header {
        transform: translateY(0%);
      }
    }

    &.headroom--unpinned {
      transform: translateY(-100%);

      & ~ .site-header {
        transform: translateY( #{px-to-em($breadcrumbs-height * -1)}  ) ;
      }
    }

    &__frame {
      height: px-to-em($breadcrumbs-height);
    }
  }
}
