.copy-block-awards {
  //@include section;

  margin-bottom: px-to-em($spacing__lg);
  margin-top: px-to-em($spacing__lg);

  &__grid {
    text-align: center;

    img {
      margin:0 auto px-to-em($spacing__md);
      display: block;
    }
  }

  @include respond-to('small') {
    $grid-spacing: px-to-em(5px);

    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      img {
        margin:0 $grid-spacing;
        flex-shrink: 0;
        flex-basis: auto;
        max-width: calc(33.33333% - #{$grid-spacing * 2});
        margin-bottom: $grid-spacing * 2;
      }
    }
  }
}
