.profile-header {
  $font-size: 13px;

  @include clearfix;
  background-color: $color__blue;
  position: relative;
  //structure based
  &__background {
    @include bg-cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background-position: center;
  }

  &__figure {
    float: left;
    width: px-to-em(140px);
    margin-right: px-to-em($wrapper-padding--mobile);
    overflow: hidden;

    & + .profile-header__content {

      .profile-header__primary {
        padding: px-to-em($wrapper-padding--mobile);
        padding-left: 0;
        //display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: px-to-em(165px);
      }

      .profile-header__name {
        display: block;
      }
    }
  }

  &__snapshot {
    display: block;
    min-width: 100%;
    max-width: 100%;
  }

  &__content {
    color: $color__white;
  }

  &__content,
  &__figure {
    position: relative;
    z-index: 2;
  }

  &__primary {
    padding: px-to-em($wrapper-padding) px-to-em($wrapper-padding--mobile);
  }

  &__secondary {
    background-color: $color__black;
    clear: left;
    text-align: center;
    padding: px-to-em($spacing__sm * 1.5) px-to-em($wrapper-padding--mobile) px-to-em($spacing__sm);
  }

  &__locations {
    display: inline;
  }

  &__location {
    //divider for more than 1 office
    margin-right: 1.3em;

    &:after {
      @include center-align(vertical);
      content: '·';
      font-size: 2em;
      right: -0.5em;
      display: inline-block;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }

  &__position {
    border-right: 1px solid $color__gray-shady-lady;
    padding-right: 0.75em;
    margin-right: 0.5em;
    display: inline-block;
    white-space: normal;

    &:last-child {
      border-right: none;
    }
  }
  // Typography
  &__name {
    font-size: px-to-em(calc($font-size__page-title--mobile/1.75));
    line-height: 1.15;
    vertical-align: middle;
    margin-right: 0.25em;
    display: inline;
  }

  &__vcard {
    vertical-align: middle;
    color: $color__brand-gold;
    margin-top: px-to-em($spacing__unit);
  }

  &__printer, &__pdf {
    vertical-align: middle;
    color: $color__brand-gold;
    margin-top: px-to-em($spacing__unit);
    margin-left: px-to-em($spacing__unit);
    cursor: pointer;
  }

  &__position {
    @include titles-entity;
  }

  @include respond-to('landscape') {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0;

    &__figure {
      width: 36%;
      float: left;
      margin-top: 0;
      max-width: px-to-em(405px);
      flex-shrink: 0;

      & + .profile-header__content {
        padding: px-to-em($wrapper-padding--mobile);

        .profile-header__primary,
        .profile-header__secondary {
          margin: 0;
        }

        .profile-header__name {
          display: inline;
        }

        .profile-header__primary {
          min-height: inherit;
          display: block;
        }
      }
    }

    &__content {
      flex-grow: 1;
      padding: px-to-em($spacing__xl) px-to-em($wrapper-padding--mobile);
    }

    &__primary,
    &__secondary {
      max-width: px-to-em($wrapper-max-width);
      margin: 0 auto;
    }

    &__primary {
      padding: 0;
    }

    &__secondary {
      background-color: transparent;
      clear: none;
      text-align: left;
      padding: px-to-em($spacing__unit) 0 0;
    }

    &__name {
      display: inline;
    }

    &__vcard {
      margin-top: 0;
    }

    &__printer {
      margin-top: 0;
    }
  }

  @include respond-to('small') {
    &__figure {
      width: 35%;
    }

    &__vcard,
    &__printer,
    &__pdf {

      &:hover {
        color: $color__white;
      }
    }
    //typography
    &__name {
      font-size: px-to-em($font-size__h1);
    }

    &__vcard,
    &__printer,
    &__pdf {
      $icon-size: px-to-em(20px);

      &:before {
        font-size: $icon-size;
      }
    }
  }

  @include respond-to('medium') {
    //typography
    &__name {
      font-size: px-to-em($font-size__page-title);
    }

    &__figure {
      margin-right: 5%;
      max-height: px-to-em(490px);
    }

    &__vcard,
    &__printer,
    &__pdf {
      $icon-size: px-to-em(26px);

      &:before {
        font-size: $icon-size;
      }
    }
  }
}
