$site-nav__font-size: 18px;
$site-nav__font-size--mobile: 30px;
$site-nav-timing: 0.5s;

.site-nav {
	position: absolute;
	height: 100vh;
	top: 0;
	padding: px-to-em($site-header-height--mobile + $spacing__md) px-to-em($wrapper-padding--mobile) 0;
	left: 0;
	background-color: $color__white--opaque;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  transition: opacity $transition-timing__site-nav ease-in,
              visibility $transition-timing__site-nav ease-in;


	&__item {
    margin-bottom: 8vh;
    overflow: hidden;
	}

  &__link {
  	@include font($font-family__hind, $site-nav__font-size--mobile);
  	text-transform: uppercase;
  	position: relative;
  	display: inline-block;
    color: $color__black;
    letter-spacing: 0.01em;

    //reveal properties
    transform: translateY(100%);
    transition: transform $transition-timing__reveal ease-in;
  }

  @include respond-to('small') {
  	display: inline-block;
  	vertical-align: middle;

  	position: static;
		height: auto;
		top: auto;
		padding: 0;
		left: auto;
		background-color: transparent;
		width: auto;
		transition: none;
		visibility: visible;
    opacity: 1;

  	&__item {
      display: inline;
      margin-bottom: 0;
      overflow: auto;

  		& + & {
  			margin-left: 2em;
  		}
  	}

  	&__link {
      font-size: px-to-em($site-nav__font-size);
      transform: none;
      transition: none;

      @include link-transition($color__brand-gold, false);

  	}
  }
  @include respond-to('medium') {
    &__link {
      font-size: px-to-em(20px);
    }
  }
}
