.related-people {
  @include section;

  $grid-spacing: px-to-em(calc($spacing__sm/2));

  &__grid {
    padding-top: px-to-em($spacing__sm);
  }

  @include respond-to('small') {
    &__grid {
      padding-top: px-to-em($spacing__md);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .person-card {
      width: calc(50% - #{$grid-spacing});
    }
  }
}