.alpha-filters {
  @include clearfix;

   margin-bottom: px-to-em($spacing__sm);

  &__letter {
    float: left;
    width: 15%;
    text-align: center;
  }

  &__filter {
    @include font($font-family__body, $font-size__h3--mobile);

    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;

    &--disabled {
      color: $color__gray-shady-lady;
      cursor: not-allowed;
    }
  }


  &__content {
    & + & {
      margin-top: px-to-em($spacing__md);
    }
  }

  @include respond-to('landscape') {
    &__letter {
      width: 7.5%;
      margin-top: 1em;
    }
  }

  @include respond-to('small') {
    margin-bottom: px-to-em($spacing__md);

    &__filter {
      font-size: px-to-em($font-size__h3);
    }
  }

  @include respond-to('medium') {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &__letter {
      width: auto;
      flex-grow: 1;
    }
  }
}
