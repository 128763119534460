.related-services {
	@include section;

  &__list-item {
    margin-bottom: 0.5em;

    &:after {
      content: ',';
      margin-right: 0.5em;
      font-size: px-to-em($font-size__h4--mobile);
      color: $color__gray-shady-lady;
    }

    &:last-child {
      &:after {
        content: '';

        margin-right: 0;
      }
    }
  }

  &__link {
    @include titles-entity();
  }

	@include respond-to('small') {
		&__list-item {
      margin-bottom: 0;
      display: inline-block;

      &:after {
        font-size: px-to-em($font-size__h4);
      }
    }
    &__link {
      @include link-transition($color__brand-gold);
    }
	}
}
