.tabbed-area-tile {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 600ms linear;

  height: 0;
  overflow: hidden;

  [data-scroll-animate] {
    height: 0;
    overflow: hidden;
  }

  &.is-active {
    display: block;
    opacity: 1;
    position: relative;
    z-index: 2;
    height: auto;
    overflow: visible;

    [data-scroll-animate] {
      height: auto;
      overflow: visible;
    }

    &.is-transitioning {
      opacity: 0;
    }
  }

  &--spaced {
      padding-top: 2.1875em;
  }

  @include respond-to ('small') {

  }
}


