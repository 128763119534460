.related-offerings {
	@include section;

	$grid-spacing: px-to-em(calc($spacing__sm/2));

    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
    }
	@include respond-to('small') {
		

    .offering-card {
      width: calc(50% - #{$grid-spacing});
    }
	}
}
