$ease-fancy: cubic-bezier(0.69, 0.03, 0.17, 0.89);

.animate-reveal {
  overflow: hidden;

  &__element {
    transform: translateY(100%);
    transition: transform $transition-timing__reveal ease-in;
    display: inline-block;
  }

  .is-animating & {

    &__element {
      transform: translateY(0);
    }
  }
}
