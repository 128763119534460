.copy-block {
  @include section;

  &.copy-block--theme-grey {

    background-color: $color__gray;
    margin-bottom: 0;
    padding-top:  px-to-em($spacing__md);
    padding-bottom:  px-to-em($spacing__sm);

    ul, ol {
      color: $color__blue;
    }

    .rte-multi-column {
      &--background {
        padding-top: 0;
      }
    }

    & + .copy-block--theme-grey {
      padding-top: 0;
    }

    & + .copy-block:not(.copy-block--theme-grey),
    & + .featured-item,
    & + .related-offerings,
    & + .related-people,
    & + .related-services,
    & + .brand-carousel {
      margin-top: px-to-em($spacing__lg);
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &__content {
    & + & {
      margin-top: px-to-em($spacing__md);
    }
  }

  &.intro-copy {
	  margin-bottom: 1.5em;
  }

  @include respond-to('small') {
    &.copy-block--theme-grey {
      padding-top:  px-to-em($spacing__lg);
      padding-bottom:  px-to-em($spacing__md);

      & + .copy-block--theme-grey {
        padding-top: 0;
      }

      & + .copy-block:not(.copy-block--theme-grey),
      & + .featured-item,
      & + .related-offerings,
      & + .related-people,
      & + .related-services,
      & + .brand-carousel {
        margin-top: px-to-em($spacing__xl);
      }
    }
    &__content {
      & + & {
        margin-top: px-to-em($spacing__lg);
      }
    }
  }

  &--no-bottom-padding {
      padding-bottom: 0.1em !important;
  }
}
