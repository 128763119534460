.modal {

  &__mask {
    position: fixed;
    z-index: $zindex__modal;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }


  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    max-width: px-to-em(300px);
    margin: 0px auto;
    padding: px-to-em($spacing__sm) px-to-em($spacing__sm * 1.5);
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  &__header {
    @include titles-entity;
    font-family: $font-family__titles;
    margin-bottom: 1em;
  }

  &__body {
    margin-bottom: $spacing__md;
  }

  &__footer {
    text-align: center;

    .button + .button {
      margin-left: 1em;
    }
  }

  @include respond-to('small') {
    &__container {
      max-width: px-to-em(450px);
      padding: px-to-em($spacing__md) px-to-em($spacing__md );
    }
  }
}

//
// The following styles are auto-applied to elements with
// transition="modal" when their visibility is toggled
// by Vue.js.
//
// You can easily play with the modal transition by editing
// these styles.
//

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
