.search-results {
  $grid-spacing: px-to-em(calc($spacing__sm/2));

  @include section();

  height: 0;
  overflow: hidden;
  opacity: 1;

  &:empty {
    display: none;
  }


  &--expanded {
    height: auto;
  }

  @include respond-to('small') {
    &__people-grid {
      padding-top: px-to-em($spacing__md);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .person-card {
      width: calc(50% - #{$grid-spacing});
    }
  }
}
