.searchbox-hero {
  @include section;

  $input-size-mobile:24px;
  $icon-size-mobile: 24px;
  $searchbox-padding: 0.25em;

  .searchbox {
    border-width: 0;

    &:after {
      content: '';
      position: absolute;
      bottom:  0;
      left: 0;
      right: 100%;
      border-bottom: 3px solid $color__black;
      animation: lineAnimation 0.5s ease-out 1s forwards;
    }
  }

  // &.is-animating {
  //   .searchbox {
  //     &:after {
  //       right: 0;
  //     }
  //   }
  // }

  @keyframes lineAnimation {
    0% {right: 100%;}
    100% {right: 0}
  }

  .searchbox__input {
    font-size: px-to-em($input-size-mobile);
    padding: $searchbox-padding px-to-em($spacing__md, $input-size-mobile) $searchbox-padding 0;
  }

  .searchbox__button {
    font-size: px-to-em($icon-size-mobile);
    padding-right: 0;
  }

  @include respond-to('small') {
    $input-size:42px;
    $icon-size: 30px;

    .searchbox__input {
      font-size: px-to-em($input-size);

    }

    .searchbox__button {
      font-size: px-to-em($icon-size);

      &:hover {
        color: $color__blue;
      }
    }
  }
}
