.article-metabar {
  background-color: $color__black;
  color: $color__white;
  padding: px-to-em($spacing__sm) 0;
  text-align: center;

  &__item {
    @include font($font-family__body, $font-size__h6);

    display: inline;

    &:after {
      content: '|';
      margin: 0 px-to-em($spacing__unit);
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &__author,
  &__location-link {
    color: $color__brand-gold;

    &:after {
      content: ',';

      display: inline;
      margin-right:0.5em;
      color: $color__white;
    }

    &:last-child {
      &:after {
        display: none;
        margin-right: 0;
      }
    }
  }

  &__data {
    display: inline-block;
  }

  @include respond-to('landscape') {
    padding: 0.5em 0;
    text-align: left;
  }

  @include respond-to('small') {

    &__author,
    &__location-link {
      &:hover {
        color: $color__white;
      }
    }
  }
}
