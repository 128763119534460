.contact-callout {
  background-color: $color__blue;
  padding: px-to-em($spacing__md) 0;
  text-align: center;
  color: $color__white;

  &__title {
    @include titles-callout;

    line-height: 1.5;
    font-size: px-to-em(28px);
    position: relative;
    z-index: 2;

    a {
      $border-size: 3px;

      position: relative;
      padding: px-to-em(calc($button__padding / 2), $font-size__callout--mobile) 0;
      transition: color 0.3s, background-color 0.3s;

      &:before {
        content: '';

        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $border-size;
        background-color: $color__brand-gold;
        transition: height 0.3s;
        z-index: -1;
      }

      &:hover {
        color: $color__black;

        &:before {
          height: 100%;
        }
      }
    }
  }

  @include respond-to('small') {
    padding: px-to-em($spacing__lg) 0 px-to-em($spacing__md);
    &__title {
      @include titles-callout;
    }
    &__cta {
      padding: px-to-em(calc($button__padding / 2), $font-size__callout) 0 px-to-em(calc($button__padding / 2), $font-size__callout)
    }
  }
}
