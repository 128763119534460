.people-search {
  .searchbox-hero {
    max-width: px-to-em($wrapper-max-width--content) +  px-to-em($wrapper-padding * 2);
  }

  @include respond-to('small') {
    .searchbox-hero {
      margin-bottom: px-to-em($spacing__md);
    }
  }
}
