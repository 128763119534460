.brand-carousel {
  @include section;
  position: relative;

  $cta-offset: px-to-em(60px);

  &__cover {
		@include bg-cover;

		position: absolute;
		width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center bottom;
  }


  &__stage {
    text-align: right;
    &.alignleft {
      text-align: left;
    }
  }

  &__copy {
    background-color: rgba(255,255,255,0.85);
    padding: px-to-em($wrapper-padding--mobile) px-to-em($wrapper-padding--mobile) $cta-offset;
    text-align: left;
    position: relative;
    z-index: 2;
    min-height: px-to-em(270px);
    .alignleft & {
      padding: px-to-em($wrapper-padding--mobile*2);
    }
  }

  &__cta {
    position: absolute;

    bottom: calc($cta-offset / 3);

    .alignleft & {
      bottom: auto;
    }
  }

  &__heading {
    @include titles-heading;

	@include respond-to('small') {
		font-size: px-to-em($font-size__callout);
	}

    margin-bottom: 0;

    .alignleft & {
      margin-bottom: px-to-em($spacing__sm, $font-size__h1--mobile);
    }
  }

  &__link-prefix {
    .alignleft & {
      vertical-align: top;
    }
  }

	&__service-link {
		color: $color__blue;
		display: block;
    overflow: hidden;
    position: relative;

    &.is-transition {
      .brand-carousel__tagline {
        animation: maskAnimation 1.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }

    &.no-link {
       pointer-events: none;
       cursor: default;
    }
  }
  
  &__tagline {
    transform: translateY(0);
    display: inline-block;
  }

	&__intro {
		display: block;
  }

  @keyframes maskAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

	@include respond-to('landscape') {
    &__copy {
      width: 80%;
      display: inline-block;
    }

    &__stage {
      position: relative;
      padding-top: 30%;
    }
	}

	@include respond-to('small') {
    $cta-offset: px-to-em(60px);

    padding-right: px-to-em($wrapper-padding);
    padding-left: px-to-em($wrapper-padding);
    max-width: px-to-em($wrapper-max-width) +  px-to-em($wrapper-padding * 2);
    margin-left: auto;
    margin-right: auto;

    &__copy {
      background-color: $color__white;
      width: 75%;
      padding: px-to-em($spacing__sm * 1.5) px-to-em($spacing__md) $cta-offset;
      max-width: px-to-em(455px);
      min-height: px-to-em(300px);
    }
    .alignleft &__copy{
      max-width: px-to-em(475px);
    }

    &__service-link {
      display: inline-block;
    }

    &__cta {
      bottom: calc($cta-offset / 4);
    }
	}
}
