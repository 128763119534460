// Link Variants
@mixin link-transition($line-color, $defineStructure: true) {

  @if $defineStructure == true {
    position: relative;
    display: inline-block;
  }


  &:before {
    content: '';

    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    background-color: $line-color;
    height: 2px;
    transition: width 0.25s,
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}
