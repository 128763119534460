$listing-padding: px-to-em(22px);

.listing-insights {
  $font-size__abstract: 20px;

  &__item {

    & + & {
			padding-top: $listing-padding;
			margin-top: $listing-padding;
			border-top: 1px solid $color__blue;
		}
  }

  &__category {
  	@include font($font-family__hind, $font-size__h6, semibold );

  	text-transform: uppercase;
  	display: block;
  	color: $color__blue;
  }

  &__title {
  	@include font($size: $font-size__h2--mobile);


    background-image: linear-gradient(to right, #{$color__brand-gold} 100%, #{$color__brand-gold} 100%);
    background-position: 0 px-to-em(20px,$font-size__h2--mobile);
    background-repeat: no-repeat;
    background-size: 0 3px;
    transition: background 0.25s,
                opacity 0.25s;
    opacity: 0.75;

    &:hover {
      background-size: 100% 3px;
      opacity: 1;
    }

  }

  &__abstract {
    @include font($family: $font-family__hind);

    margin-top: 0.5em;
  }


  @include respond-to('small') {
  	$listing-padding: px-to-em(44px);

    &__item {

      & + & {
        padding-top: $listing-padding;
        margin-top: $listing-padding;
        border-top: 1px solid $color__blue;
      }
    }

	  &__title {
	  	@include font($size: $font-size__h2);

      background-position: 0 px-to-em(40px,$font-size__h2);
	  }

    &__abstract {
      font-size: px-to-em($font-size__abstract);
    }
  }
}
