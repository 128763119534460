.services-accordion {

  $icon-size: 40px;

  &__toggle {
    padding-right: px-to-em($icon-size);
	padding-left: 1.2em;

  }

  &__toggle-icon {
    @include font($family: $font-family__body,$size: $icon-size);
    @include center-align(vertical);

    position: absolute;
    font-size: px-to-em($icon-size);
    right: px-to-em(calc($wrapper-padding--mobile / 2), $icon-size);
    z-index: 2;
    color: $color__blue;
    height: px-to-em($icon-size, $icon-size);
    transition: color 0.25s ease-out 0.15s;

    &:after {
      content: '+';
      font-size: 1.2em;
      margin-left: 0.5em;
    }
  }

  &__list-item {
    padding-bottom: px-to-em($spacing__unit);
    &:first-child {
      padding-bottom: px-to-em($spacing__unit);
      .services-accordion__link {
        @include titles-entity();
      }
    }
    &:last-child {
      padding-bottom: px-to-em($spacing__unit * 2);
    }
  }

  &__list {
    padding:  0 px-to-em($spacing__sm);
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
  }

  &--expanded {
    .services-accordion__toggle-icon {
      // color: $color__white;

      &:after {
        content: '–';
      }
    }
    .services-accordion__list {
      max-height: px-to-em(650px);
    }
  }

	@include respond-to('small') {
    &__toggle-icon {
      right: px-to-em($wrapper-padding--mobile, $icon-size);
    }

    &__toggle {
      &:hover {
        .services-accordion__toggle-icon {
          color: $color__white;
        }
      }
    }

    &__link {
      @include link-transition($color__brand-gold);
    }
	}
}
