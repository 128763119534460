.share-this {
  z-index: $zindex__back-to-top;
  // top: px-to-em($breadcrumbs-height + $site-header-height + $spacing__md);
  top: 0;
  left: 0;
  position: absolute;
  display: none;
  width: 100%;

  &__sections {
    position: relative;

	@media only screen and (max-width: $screen-lg) {
		.wrapper, .wrapper--content {		
			margin-right: px-to-em($spacing__md * 2);
		}
	}
	@media only screen and (max-width: $screen-sm - 1) {
		.wrapper, .wrapper--content {		
			margin-right: 0;
		}
	}
  }

  &__stickiness {
    @include center-align(horizontal);
    width: 100%;
    height: 0;
  }

  &__component {
    float: right;
  }

  &__label {
    $border-size: 3px;

    @include font($font-family__body, $font-size__h6 );

    text-transform: uppercase;
    padding-top: 0.3em;
    padding-bottom: 0.1em;
    display: inline-block;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $border-size;
      background-color: $color__brand-gold;

      transition: height 0.3s;
      z-index: -1;
    }

    &:hover {
      &:before {
        height: 100%;
      }
    }
  }

  &__component {
    &:hover {
    .social-links {
        opacity: 1;
        transform: translateY(0);

        transition: transform 0.4s ease-in,
                    opacity 0.7s;
        }
    }
  }

  .social-links {
    text-align: center;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.35s ease-in,
                opacity 0.35s;

    &__item {
      display: block;
      padding-top: 1em;
    }

    &__icon {
      font-size: px-to-em(22px);
      color: $color__brand-gold;

      &.icon-mail {
        font-size: px-to-em(26px);
      }
    }
  }

  &__dropdown {
    overflow: hidden;

  }

	@include respond-to('small') {
    display: block;
    $sticky-offset: 20px;


    &.is-bottom-sticky {
      bottom: 0;
      top: auto;
    }

    &__label {
      font-size: 1em;
    }

    &__stickiness {
      transition: top 0.25s linear;

      &.is-sticky {
        position: fixed;
        top: px-to-em($site-header-height + $sticky-offset);


        .headroom--pinned ~ #articles-detail-app  & {
          top: px-to-em($site-header-height + $sticky-offset + $breadcrumbs-height);
        }
      }

      .is-bottom-sticky  &{
        position: absolute;
        top: auto;
        transition: none;
      }
    }

    &__trigger {
      //icon arrrow up
      &:before {
        font-size: px-to-em(26px);
      }
    }
  }

  .social-links {
    &__icon {
      &:hover {
        color: $color__blue;
      }
    }
  }

}
