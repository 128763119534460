.autocomplete {

    &-wrapper {
        position: relative;
    }

    &-active {
        .autocomplete-results {
            display: block;
            border-left: 3px solid $color__black;
            border-right: 3px solid $color__black;
            border-bottom: 3px solid $color__black;        
        }
    }

    &-results {
        display: none;
        position: absolute;
        background-color: $color__white;
        width: 100%;       
        z-index: 10;
        max-height: 180px;
        overflow-y: scroll;

        &__item {            
            &:not(:last-child) {
                border-bottom: 1px solid $color__gray-black-squeeze;            
            }

            a {
                display: block;                
                padding: 5px 25px;
                transition: all 0.25s ease-in-out;

                &:hover {                    
                    background-color: $color__gray-black-squeeze;
                }
            }
        }
    }
}